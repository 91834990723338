import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ReactComponentElement } from "react";

export type FAQProps = {
  title: string;
  children: React.ReactNode;

  defaultExpanded?: boolean;

  startIcon?: ReactComponentElement<any>;
};

export const FAQ = ({
  title,
  children,
  defaultExpanded,
  startIcon,
}: FAQProps) => {
  return (
    <Accordion
      elevation={0}
      defaultExpanded={defaultExpanded}
      sx={{
        backgroundColor: "transparent",
        borderBottom: "1px solid #CEE0FD",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        sx={[
          {
            minHeight: "revert",
            paddingX: "12px",
            paddingTop: "16px",
            paddingBottom: "16px",
            margin: "0",
            justifyContent: "flex-start",
            gap: "6px",
            "&:hover": {
              background: "#E7F1FB",
            },
          },
          {
            "> .MuiAccordionSummary-content": {
              border: "none",
              paddingLeft: "0",
              paddingRight: "0",
              margin: "0px",
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
            },
          },
          {
            "&.Mui-expanded": {
              paddingTop: "16px",
              paddingBottom: "8px",
              minHeight: "revert",
              background: "#E7F1FB",
              margin: "0",
            },
          },
        ]}
        expandIcon={
          <ExpandMore
            sx={{
              width: "24px",
              height: "24px",
              color: "#373737",
            }}
          />
        }
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {startIcon && startIcon}
        <Typography variant="bodyL" fontWeight="bold">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          background: "#E7F1FB",
          paddingTop: "0",
          paddingBottom: "24px",
          paddingX: "12px",
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
