import {
  FormControl,
  FormHelperText,
  SxProps,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ReactNode } from "react";

export type HKSelectOption = {
  value: string | number;
  label: string;
};

export const HKSelect = ({
  handleChange,
  value,
  options,
  name,
  label,
  errors,
  placeholder,
  required,
  selectSX,
}: {
  handleChange: (
    event: SelectChangeEvent<string | number>,
    child: ReactNode,
  ) => void;
  value: number | string | undefined;
  options: HKSelectOption[];
  name: string;
  label: string;
  errors?: string;
  placeholder?: string;
  required?: boolean;
  selectSX?: SxProps;
}) => {
  return (
    <div className="flex flex-col gap-1.5">
      <FormControl error={errors && errors.length > 0 ? true : false}>
        <Typography
          id={name}
          component="label"
          variant="body"
          marginBottom={"4px"}
        >
          {label}
          {required && "*"}
        </Typography>
        <Select
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          id={name}
          name={name}
          required={required}
          sx={selectSX}
        >
          {options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{errors}</FormHelperText>
      </FormControl>
    </div>
  );
};
