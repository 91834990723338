/* eslint-disable no-use-before-define */
import {
  Autocomplete,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";

export function HKChipInput({
  label,
  name,
  value,
  errors,
  onChange,
  required,
  setTouched,
  touched,
}: {
  label: string;
  name: string;
  value: string;
  errors: string;
  required?: boolean;
  touched?: boolean;
  onChange: ReturnType<typeof useFormik>["handleChange"];
  setTouched: ReturnType<typeof useFormik>["setTouched"];
}) {
  const [inputValue, setInputValue] = React.useState("" as any);

  return (
    <div className="flex flex-col gap-1.5">
      <Typography id={name} component="label" variant="titleS">
        {label} {required && "*"}
      </Typography>
      <Autocomplete
        multiple
        freeSolo
        id={`${name}`}
        options={[]}
        value={value && value.length > 0 ? value.split(";") : []}
        inputValue={inputValue}
        onChange={(_event, newValue) => {
          onChange({
            target: { name: name, value: newValue.join(";") } as any,
          });
        }}
        onInputChange={(_event, newInputValue) => {
          const options = newInputValue.split(" ");

          if (options.length > 1) {
            onChange({
              target: {
                name: name,
                value: value
                  .split(";")
                  .concat(options)
                  .map((x) => x.trim())
                  .filter((x) => x)
                  .join(";"),
              },
            });
            setInputValue("");
          } else {
            setTouched({ [name]: false }); // This is a hack to remove the error message when the user is typing -- LH, 2024-03-15
            setInputValue(newInputValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={touched && errors && errors.length > 0 ? true : false}
            onBlur={() => {
              if (inputValue && inputValue.length > 0) {
                onChange({
                  target: {
                    name: name,
                    value: `${value && value.length > 0 ? `${value};` : ""}${inputValue}`,
                  } as any,
                });
                setInputValue("");
              }
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "auto",
              },
            }}
            inputProps={{
              "data-testid": `input-${name}`,
              ...params.inputProps,
            }}
          />
        )}
      />
      <FormHelperText>{errors}</FormHelperText>
    </div>
  );
}
