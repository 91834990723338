import { AccessTime } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Trans } from "react-i18next";

import { Callout } from "../../../../components/design-system";

export const UnpublishedCallout = () => {
  return (
    <Callout type="success">
      <AccessTime
        sx={{
          width: "24px",
          height: "24px",
          color: "#056753",
        }}
      />
      <Typography
        variant="body"
        sx={{
          color: "#056753",
        }}
      >
        <Trans
          i18nKey="employer-portal.onboarding.health-budget.unpublished"
          components={{
            contact: (
              // Anchor is given content by the Trans component
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                className="underline"
                style={{ color: "#105A9A", fontWeight: "700" }}
                href="https://www.healthkey.health/contact-employers"
                rel="noreferrer"
                target="_blank"
              />
            ),
          }}
        />
      </Typography>
    </Callout>
  );
};
