import axios from "axios";

import { useUpdatePayerMutation } from "../store/slices/api/payers";
import {
  useAcceptTermsAndConditionsMutation,
  useRespondToUserJoiningEmployerMutation,
  useUpdateProvidersForEmployerMutation,
} from "../store/slices/healthkeyAPI";
import {
  EmployerBudgetFormData,
  EmployerInformation,
  EmployerOnboardingRestrictionFormData,
  EmployerWelcomeFormData,
  Payer,
  TopUpRule,
} from "../types/types";

export const sendEmployerSuggestion = async (
  employerName: string,
  contactEmail: string | undefined,
  accessToken: string | null,
): Promise<any | undefined> => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
  };
  return await axios.post(
    `${process.env.REACT_APP_SERVER_API_URL}/payers/suggestions`,
    { employerName, contactEmail },
    { headers: headers },
  );
};

export const approveUserToEmployer = async (
  employerId: string,
  userId: string,
  approve: boolean,
  respondToUserJoiningEmployerMutation: ReturnType<
    typeof useRespondToUserJoiningEmployerMutation
  >[0],
): Promise<EmployerInformation> => {
  return await respondToUserJoiningEmployerMutation({
    userId: userId,
    employerId: employerId,
    approve: approve,
  }).unwrap();
};

export const getEmployerInfoForUserEmployer = async (
  employerId: string,
  accessToken: string | null,
): Promise<any | undefined> => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
  };
  return await axios.get(
    `${process.env.REACT_APP_SERVER_API_URL}/payers/` +
      employerId +
      "/employerInfo",
    { headers: headers },
  );
};

export const updateProvidersForEmployer = async (
  employerId: string,
  selectedProvidersIds: Array<string>,
  updateProvidersForEmployerMutation: ReturnType<
    typeof useUpdateProvidersForEmployerMutation
  >[0],
): Promise<any | undefined> => {
  return await updateProvidersForEmployerMutation({
    selectedProvidersIds: selectedProvidersIds,
    employerId: employerId,
  }).unwrap();
};

const calculateFrequency = (
  currentEmployer: Payer,
): "one_off" | "recurrent" => {
  if (!currentEmployer.payerPolicy) {
    return "one_off";
  }

  if (
    currentEmployer.payerPolicy?.topUpPolicy.type.toLowerCase() === "one_off"
  ) {
    return "one_off";
  } else {
    return "recurrent";
  }
};

const calculateStrategyValue = (
  currentEmployer: Payer,
): "none" | "standard" => {
  if (!currentEmployer.payerPolicy) {
    return "none";
  }

  if (
    currentEmployer.payerPolicy.topUpPolicy.topUpAmount &&
    currentEmployer.payerPolicy.topUpPolicy.topUpAmount > 0
  ) {
    return "standard";
  } else {
    return "none";
  }
};

export const convertEmployerToBudgetFormData = (
  employer: Payer | undefined,
): EmployerBudgetFormData => {
  return {
    topUpAmount: employer?.payerPolicy?.topUpPolicy.topUpAmount
      ? employer?.payerPolicy.topUpPolicy.topUpAmount
      : 0,
    dayOfTheTopUp: employer?.payerPolicy?.topUpPolicy.dayOfTheTopUp
      ? employer?.payerPolicy.topUpPolicy.dayOfTheTopUp
      : 0,
    monthOfTheTopUp: employer?.payerPolicy?.topUpPolicy.monthOfTheTopUp
      ? employer?.payerPolicy.topUpPolicy.monthOfTheTopUp
      : 0,
    topUpFrequency: employer ? calculateFrequency(employer) : "one_off",
    renewFrequency: employer?.payerPolicy?.topUpPolicy
      ? (employer?.payerPolicy?.topUpPolicy.type as "yearly" | "monthly")
      : "one-off",
    strategyValue: employer ? calculateStrategyValue(employer) : "none",
    enableSalarySacrifice: employer?.enableSalarySacrifice
      ? employer.enableSalarySacrifice
      : false,
  };
};

export const convertEmployerToOnboardingRestrictionFormData = (
  employer: Payer | undefined,
): EmployerOnboardingRestrictionFormData => {
  return {
    restrictDomain:
      employer?.emailDomain && employer?.emailDomain.length > 0 ? true : false,
    limitNumberOfEmployees:
      employer?.payerPolicy?.allowedNumberOfUsers &&
      employer?.payerPolicy?.allowedNumberOfUsers > 0
        ? true
        : false,
    allowedNumberOfUsers: employer?.payerPolicy?.allowedNumberOfUsers
      ? employer.payerPolicy.allowedNumberOfUsers
      : -1,
    emailDomain: employer?.emailDomain ? employer.emailDomain : "",
  };
};

export const convertEmployerToWelcomeFormData = (
  employer: Payer | undefined,
): EmployerWelcomeFormData => {
  return {
    onboardingText: employer?.onboardingText ? employer.onboardingText : "",
  };
};

export const updateEmployer = async (
  employerBudgetFormData: EmployerBudgetFormData,
  onboardingRestrictionsFormData: EmployerOnboardingRestrictionFormData,
  welcomeFormData: EmployerWelcomeFormData,
  employer: Payer,
  updateEmployerMutation: ReturnType<typeof useUpdatePayerMutation>[0],
  acceptTAndCMutation:
    | ReturnType<typeof useAcceptTermsAndConditionsMutation>[0]
    | null,
  automaticallyPublishProviders?: boolean | undefined,
): Promise<void> => {
  return updateEmployerFromValues(
    employer.id,
    employer.name,
    employer.payerSize,
    employerBudgetFormData.topUpFrequency,
    employerBudgetFormData.strategyValue,
    employerBudgetFormData.renewFrequency,
    !onboardingRestrictionsFormData.limitNumberOfEmployees,
    [],
    employerBudgetFormData.topUpAmount,
    onboardingRestrictionsFormData.allowedNumberOfUsers,
    employerBudgetFormData.dayOfTheTopUp,
    employerBudgetFormData.monthOfTheTopUp,
    welcomeFormData.onboardingText && welcomeFormData.onboardingText?.length > 0
      ? welcomeFormData.onboardingText
      : undefined,
    onboardingRestrictionsFormData.emailDomain &&
      onboardingRestrictionsFormData.emailDomain.length > 0
      ? onboardingRestrictionsFormData.emailDomain
      : undefined,
    employerBudgetFormData.enableSalarySacrifice,
    employer.logo,
    automaticallyPublishProviders === undefined
      ? employer.automaticallyPublishProviders
      : automaticallyPublishProviders,
    updateEmployerMutation,
    acceptTAndCMutation,
  );
};

const updateEmployerFromValues = async (
  employerId: string,
  employerName: string,
  payerSize: number | null,
  topUpFrequency: string,
  strategyValue: string,
  renewFrequency: string,
  allEmployeesChecked: boolean,
  topUpRules: TopUpRule[],
  topUpAmount: number,
  allowedNumberOfUsers: number,
  dayOfTheTopUp: number,
  monthOfTheTopUp: number,
  onboardingText: string | undefined,
  emailDomain: string | undefined,
  enableSalarySacrifice: boolean,
  logo: string | null,
  automaticallyPublishProviders: boolean,
  updateEmployerMutation: ReturnType<typeof useUpdatePayerMutation>[0],
  acceptTAndCMutation:
    | ReturnType<typeof useAcceptTermsAndConditionsMutation>[0]
    | null,
): Promise<void> => {
  let topUpPolicy;
  if (topUpFrequency === "one_off") {
    if (strategyValue === "none") {
      topUpPolicy = {
        type: "one_off",
        topUpAmount: null,
        usersToRandomise: 0,
        rules: new Array<TopUpRule>(),
      };
    }
    if (strategyValue === "standard") {
      topUpPolicy = {
        type: "one_off",
        topUpAmount: topUpAmount,
        usersToRandomise: 0,
        rules: new Array<TopUpRule>(),
      };
    }
    if (strategyValue === "random") {
      topUpPolicy = {
        type: "one_off",
        topUpAmount: null,
        usersToRandomise: 0,
        rules: topUpRules,
      };
    }
  } else {
    topUpPolicy = {
      type: renewFrequency,
      topUpAmount: topUpAmount,
      dayOfTheTopUp: dayOfTheTopUp,
      monthOfTheTopUp: monthOfTheTopUp,
      usersToRandomise: 0,
      rules: new Array<TopUpRule>(),
    };
  }

  let numberOfUsers = -1;
  if (!allEmployeesChecked) {
    numberOfUsers = allowedNumberOfUsers;
  }

  const body: Partial<Payer> = {
    name: employerName,
    payerSize: payerSize,
    id: employerId,
    logo,
    onboardingText: onboardingText,
    emailDomain: emailDomain,
    enableSalarySacrifice: enableSalarySacrifice,
    automaticallyPublishProviders: automaticallyPublishProviders,
    payerPolicy: {
      allowedNumberOfUsers: numberOfUsers,
      topUpPolicy: topUpPolicy,
    },
  };
  await updateEmployerMutation(body).unwrap();
  await (acceptTAndCMutation ? acceptTAndCMutation(employerId).unwrap() : null);
};

export const readAllEmployers = async (
  accessToken: string | null,
): Promise<any | undefined> => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
  };
  return await axios.get(`${process.env.REACT_APP_SERVER_API_URL}/payers`, {
    headers: headers,
  });
};
