import { Button, FormGroup, Switch, Typography } from "@mui/material";
import { useFormik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Callout, HKFormControlLabel } from "../../../components/design-system";
import { schemaForHealthBudgetEditor } from "./EmployerSettingsBudget/HealthBudgetEditor";
import { UnpublishedCallout } from "./components/UnpublishedCallout";
import {
  convertEmployerToBudgetFormData,
  convertEmployerToOnboardingRestrictionFormData,
  convertEmployerToWelcomeFormData,
  updateEmployer,
} from "../../../services/employer-service";
import { useAppSelector } from "../../../store/hooks";
import {
  usePayerForUserQuery,
  useUpdatePayerMutation,
} from "../../../store/slices/api/payers";
import { useCurrentEmployerQuery } from "../../../store/slices/healthkeyAPI";
import { EmployerBudgetFormData } from "../../../types/types";
import EmployerSettingsTemplate from "./page-template/EmployerSettingsTemplate";

const SalarySacrificeEditor = ({
  formikEmployerProfile,
  payerId,
}: {
  formikEmployerProfile: any;
  payerId?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="body">
        <Trans
          i18nKey="employer-portal.onboarding.salary-sacrifice.description"
          components={{
            br: <br />,
            insights: (
              // Anchor is given content by the Trans component
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                className="underline"
                style={{ color: "#105A9A" }}
                href={`http://localhost:3000/employerPortal/${payerId}/dashboard`}
                rel="noreferrer"
                target="_blank"
              />
            ),
          }}
        />
      </Typography>
      <FormGroup>
        <div className="flex flex-col gap-4 pt-6">
          <HKFormControlLabel
            control={
              <Switch
                name="enableSalarySacrifice"
                checked={formikEmployerProfile.values.enableSalarySacrifice}
                onChange={formikEmployerProfile.handleChange}
              />
            }
            label={t("employer-portal.onboarding.salary-sacrifice.enable")}
          />
        </div>
      </FormGroup>
    </div>
  );
};

export const EmployerSettingsSalarySacrifice = () => {
  const { t } = useTranslation();
  const loggedInUser = useAppSelector((state) => state.userDetails.user);
  const { payerId } = useParams();

  const [updatePayerMutation] = useUpdatePayerMutation();
  const { data: selectedPayer } = usePayerForUserQuery({
    userId: loggedInUser?.id,
    payerId,
  });

  const { data: currentPayer, isLoading: currentPayerIsLoading } =
    useCurrentEmployerQuery(selectedPayer?.id, {
      skip: !selectedPayer,
    });

  const formikEmployerProfile = useFormik({
    initialValues: {
      ...convertEmployerToBudgetFormData(currentPayer),
    },
    enableReinitialize: true,
    onSubmit: async (values: EmployerBudgetFormData) => {
      try {
        await updateEmployer(
          values,
          convertEmployerToOnboardingRestrictionFormData(currentPayer),
          convertEmployerToWelcomeFormData(currentPayer),
          currentPayer!,
          updatePayerMutation,
          null,
        );
        toast.success("Your changes have been successfully processed.");
      } catch (e) {
        toast.error("There has been a problem saving your changes.");
      }
    },
    validate: async (values) => {
      let schema = schemaForHealthBudgetEditor(values);

      try {
        await schema.validate(values, { abortEarly: false });
        return {};
      } catch (err: any) {
        return err.inner.reduce((e: any, a: any) => {
          return { ...e, [a.path]: a.message };
        }, {});
      }
    },
  });

  console.log("currentPayer", currentPayer);

  return (
    <EmployerSettingsTemplate
      loading={currentPayerIsLoading}
      selectedTab="salary-sacrifice"
    >
      <div className="flex flex-col">
        <Typography
          variant="titleL"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            paddingBottom: "0.5rem",
          }}
        >
          {t("employer-portal.settings.salary-sacrifice.title")}
        </Typography>
        <div className="flex flex-col gap-4">
          <Callout>
            <Typography
              variant="body"
              sx={{
                color: "#105A9A",
              }}
            >
              <Trans
                i18nKey="employer-portal.onboarding.salary-sacrifice.callout"
                components={{
                  b: <b />,
                  "salary-sacrifice": (
                    // Anchor is given content by the Trans component
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href="https://www.gov.uk/guidance/salary-sacrifice-and-the-effects-on-paye"
                      className="underline"
                      style={{ color: "#105A9A", fontWeight: "700" }}
                      rel="noreferrer"
                      target="_blank"
                    />
                  ),
                }}
              />
            </Typography>
          </Callout>
        </div>
        <div className="py-6">
          <SalarySacrificeEditor
            formikEmployerProfile={formikEmployerProfile}
            payerId={currentPayer?.id}
          />
        </div>

        <div className="flex flex-col gap-6">
          {!currentPayer?.published && <UnpublishedCallout />}
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              formikEmployerProfile.handleSubmit();
            }}
            disabled={!currentPayer?.published}
          >
            {t("employer-portal.settings.health-budget.save-changes")}
          </Button>
        </div>
      </div>
    </EmployerSettingsTemplate>
  );
};

export default EmployerSettingsSalarySacrifice;
