import { useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import {
  HealthKeyContainer,
  HealthKeyPage,
} from "../../../components/design-system";
import { PageLoader } from "../../../components/design-system/loader/PageLoader";
import { PayerSetupComplete } from "../../../components/employer-portal/PayerSetupComplete";
import Footer from "../../../components/footer/Footer";
import { useAppSelector } from "../../../store/hooks";
import { useHeaderQuery } from "../../../store/slices/api/aggregation-hooks";
import { useEmployerInfoQuery } from "../../../store/slices/healthkeyAPI";
import { OnboardingTaskEnum } from "../../../types/types";
import { LimitExceededCallout } from "./LimitExceededCallout";
import NavigationTabsEmployer from "./NavigationTabsEmployer";

export const EmployerPortalPageTemplate = ({
  meta,
  isLoading,
  selectedTab,
  children,
  payerId,
}: {
  meta: { title: string; description?: string };
  isLoading: boolean;
  selectedTab: string;
  children: React.ReactNode;
  payerId: string | undefined;
}) => {
  const loggedInUser = useAppSelector((state) => state.userDetails.user);

  const {
    loggedInEmployer,
    onboardingTasks,
    payersForUser,
    isLoading: headerIsLoading,
    wallet,
  } = useHeaderQuery(loggedInUser);

  const { data: employerInformation } = useEmployerInfoQuery(payerId, {
    skip: headerIsLoading,
  });

  if (isLoading || headerIsLoading) {
    return <PageLoader />;
  }

  return (
    <HealthKeyContainer>
      <HealthKeyPage
        meta={meta}
        headerProps={{
          title: meta.title,
          showHealthBudget: true,
          showSkipToBody: true,
          showSkipToProviders: true,
          showSkipToSearch: true,
          loggedInEmployer: loggedInEmployer ?? null,
          onboardingTasks,
          payersForUser,
          wallet,
        }}
      >
        <div className="flex flex-col w-full">
          <NavigationTabsEmployer selectedTab={selectedTab} payerId={payerId} />
          {employerInformation && employerInformation.paidLimitReached && (
            <div className="pb-4">
              <LimitExceededCallout
                numberOfAccountsOverLimit={
                  employerInformation.numberOfAccountsOverLimit
                }
              />
            </div>
          )}
          {children}
          <Footer selectedTab={"0"} />
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </HealthKeyPage>
    </HealthKeyContainer>
  );
};
