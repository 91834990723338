import { CheckCircleRounded } from "@mui/icons-material";
import { Radio, RadioGroup, RadioGroupProps } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { HKFormControlLabel } from "../../../../components/design-system/form/HKFormControlLabel";

const RadioAccordion = ({
  value,
  onChange,
  options,
}: {
  value: string;
  onChange: RadioGroupProps["onChange"];
  options: {
    label: string;
    content: React.ReactNode;
    value: string;
  }[];
}) => {
  const { t } = useTranslation();

  return (
    <RadioGroup
      className="flex flex-col gap-4"
      value={value}
      onChange={onChange}
    >
      {options.map((option, index) => {
        return (
          <div
            key={index}
            className={`hover:shadow-large border-2 border-blue-2 rounded-lg ${value === option.value ? "border-blue-2" : "border-grey-1"}`}
          >
            <HKFormControlLabel
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row-reverse",
                justifyContent: "space-between",
                paddingX: "1rem",
                paddingY: "0.75rem",
                margin: 0,
              }}
              value={option.value}
              control={
                <Radio
                  checkedIcon={<CheckCircleRounded />}
                  sx={{
                    width: "24px",
                    height: "24px",
                    color: "#105A9A",
                    "&.Mui-checked": {
                      color: "#2583D4",
                    },
                  }}
                />
              }
              label={option.label}
              labelPlacement="start"
            />
            {value === option.value && option.content}
          </div>
        );
      })}
    </RadioGroup>
  );
};

export default RadioAccordion;
