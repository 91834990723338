import { Typography } from "@mui/material";

export type BodyProps = {
  children: React.ReactNode;
};

/**
 * @deprecated
 */
export const Body = ({ children }: BodyProps) => (
  <Typography variant="body">{children}</Typography>
);
