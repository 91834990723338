import { InfoOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

export const LimitExceededCallout = ({
  numberOfAccountsOverLimit,
}: {
  numberOfAccountsOverLimit: number;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row gap-2 py-2 px-3 feedback-error-light items-center rounded">
      <InfoOutlined
        sx={{
          width: "32px",
          height: "32px",
          color: "#BD0300",
        }}
      />
      <div>
        <Typography variant="body" color="#BD0300">
          <Trans
            i18nKey="employer-portal.onboarding.limit-exceeded-message"
            components={{
              contact: (
                <a
                  className="underline"
                  style={{ color: "#BD0300" }}
                  href="https://www.healthkey.health/help/contact"
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
            values={{
              numberOfAccounts: numberOfAccountsOverLimit,
            }}
          />
        </Typography>
      </div>
    </div>
  );
};
