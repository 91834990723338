import { SvgIconComponent } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";

export type TextFieldAdornmentProps = {
  Icon: SvgIconComponent;
};

export const TextFieldAdornment = ({ Icon }: TextFieldAdornmentProps) => {
  return (
    <InputAdornment position="start">
      <Icon sx={{ color: "#2C2C2C" }} />
    </InputAdornment>
  );
};
