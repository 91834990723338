import {
  Favorite,
  FavoriteRounded,
  Message,
  MessageRounded,
  Payments,
  PaymentsRounded,
  Visibility,
  VisibilityRounded,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { PageLoader } from "../../../../components/design-system/loader/PageLoader";
import { EmployerPortalPageTemplate } from "../../page-template/EmployerPortalPageTemplate";

const EmployerSettingsTemplate = ({
  loading,
  selectedTab,
  children,
}: {
  loading: boolean;
  children: React.ReactNode;
  selectedTab: "budget" | "appearance" | "welcome-message" | "salary-sacrifice";
}) => {
  const { t } = useTranslation();
  const { payerId } = useParams();

  if (loading) {
    return <PageLoader />;
  }

  return (
    <EmployerPortalPageTemplate
      meta={{ title: t("employer-portal.settings.navigation.health-budget") }}
      isLoading={loading}
      selectedTab="3"
      payerId={payerId}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-4">
          <div className="flex flex-col bg-white rounded-lg py-4">
            <Link
              replace
              to={`/employerPortal/${payerId}/settings/budget`}
              className={`py-2 px-3 flex flex-row gap-2 ${selectedTab === "budget" ? "text-[#105A9A]" : "text-[#2C2C2C]"}`}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  justifyItems: "center",
                }}
              >
                <FavoriteRounded />
                <Typography
                  variant="bodyBold"
                  sx={{
                    textWrap: "nowrap",
                  }}
                >
                  {t("employer-portal.settings.navigation.health-budget")}
                </Typography>
              </Box>
            </Link>
            <Link
              replace
              to={`/employerPortal/${payerId}/settings/salary-sacrifice`}
              className={`py-2 px-3 flex flex-row gap-2 ${selectedTab === "salary-sacrifice" ? "text-[#105A9A]" : "text-[#2C2C2C]"}`}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  justifyItems: "center",
                }}
              >
                <PaymentsRounded />
                <Typography
                  variant="bodyBold"
                  sx={{
                    textWrap: "nowrap",
                  }}
                >
                  {t("employer-portal.settings.navigation.salary-sacrifice")}
                </Typography>
              </Box>
            </Link>
            <Link
              replace
              to={`/employerPortal/${payerId}/settings/appearance`}
              className={`py-2 px-3 flex flex-row gap-2 ${selectedTab === "appearance" ? "text-[#105A9A]" : "text-[#2C2C2C]"}`}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  justifyItems: "center",
                }}
              >
                <VisibilityRounded />
                <Typography
                  variant="bodyBold"
                  sx={{
                    textWrap: "nowrap",
                  }}
                >
                  {t("employer-portal.settings.navigation.appearance")}
                </Typography>
              </Box>
            </Link>
            <Link
              replace
              to={`/employerPortal/${payerId}/settings/welcome-message`}
              className={`py-2 px-3 flex flex-row gap-2 ${selectedTab === "welcome-message" ? "text-[#105A9A]" : "text-[#2C2C2C]"}`}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  justifyItems: "center",
                }}
              >
                <MessageRounded />
                <Typography
                  variant="bodyBold"
                  sx={{
                    textWrap: "nowrap",
                  }}
                >
                  {t("employer-portal.settings.navigation.welcome-message")}
                </Typography>
              </Box>
            </Link>
          </div>
          <div className="bg-white rounded-lg p-6 w-full">{children}</div>
        </div>
      </div>
    </EmployerPortalPageTemplate>
  );
};

export default EmployerSettingsTemplate;
