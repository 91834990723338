import { ArrowForwardRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";

export const MenuAction = ({
  notificationBubble,
  goToAction,
  onMouseOver,
  title,
  text,
}: {
  notificationBubble: boolean;
  goToAction: () => void;
  onMouseOver?: () => void;
  title: string;
  text: string;
}) => {
  return (
    <button
      className="flex flex-row gap-2 pr-6 px-2 py-3 w-full items-center border-b-[1px] border-blue-1 hover:bg-background motion-reduce:transition-none group focus:outline-none focus:ring focus:ring-offset-2 focus:ring-[#105a9a]"
      onClick={goToAction}
      onMouseOver={onMouseOver && onMouseOver}
    >
      <div className="flex flex-col flex-1">
        <div className="flex flex-row gap-2">
          <div className="w-[9px]">
            {
              <svg
                width="0.5625rem"
                height="1.8125rem"
                viewBox="0 0 0.5625rem 1.25rem"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden={!notificationBubble}
              >
                {notificationBubble && <title>Unseen notification</title>}
                <circle
                  cx="0.28125rem"
                  cy="0.8125rem"
                  r={notificationBubble ? "0.25rem" : "0"}
                  className="transition-all"
                  fill="#E35349"
                />
              </svg>
            }
          </div>
          <div className="flex flex-col">
            <Typography
              variant="titleXS"
              sx={{
                textAlign: "start",
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="bodyS"
              sx={{
                textAlign: "start",
              }}
            >
              {text}
            </Typography>
          </div>
        </div>
      </div>
      <div className="group-hover:translate-x-2 transition-transform">
        <ArrowForwardRounded
          sx={{
            color: "#105A9A",
            width: "20px",
            height: "20px",
          }}
        />
      </div>
    </button>
  );
};
