import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import LogoBanner from "./components/LogoCenteredInsideComponent";

export const OnboardingContentBubble = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isBiggerThanSmBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));

  const [observing, setObserving] = useState(null as null | HTMLDivElement);
  const [height, setHeight] = useState(isBiggerThanSmBreakpoint ? 31 : 55);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        const { current } = ref;
        const boundingRect = current.getBoundingClientRect();
        setHeight(boundingRect.height);
      }
    });

    if (ref?.current && !observing) {
      resizeObserver.observe(ref?.current);
      setObserving(ref?.current);
    }

    return () => {
      if (observing) {
        resizeObserver.unobserve(observing);
        setObserving(null);
      }
    };
  }, [observing, ref]);

  return (
    <>
      <div className="flex flex-col items-center rounded-lg sm:mt-0 w-full flex-1 lg:mx-auto lg:max-w-[944px] xl:max-w-[1300px] 3xl:max-w-[1500px] xl:mx-auto">
        <LogoBanner />
        <div className="min-h-[calc(min(680px,80vh))] w-full">{children}</div>
      </div>
    </>
  );
};
