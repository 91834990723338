import {
  FormControl,
  FormHelperText,
  SxProps,
  Typography,
} from "@mui/material";
import { ChangeEventHandler } from "react";

import { TextField } from "./TextField";

export const HKInput = ({
  label,
  name,
  value,
  handleChange,
  errors,
  required,
  startAdornment,
  placeholder,
  multiline,
  type,
  textFieldSX,
  maxLength,
}: {
  label: string;
  name: string;
  value: string | number | undefined;
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  errors?: string;
  required?: boolean;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  multiline?: number;
  type?: string;
  textFieldSX?: SxProps;
  maxLength?: number;
}) => {
  return (
    <div className="flex flex-col gap-1.5">
      <FormControl error={errors && errors.length > 0 ? true : false}>
        <Typography
          id={name}
          component="label"
          variant="body"
          marginBottom={"4px"}
        >
          {label}
          {required && "*"}
        </Typography>
        {multiline && multiline > 0 ? (
          <TextField
            aria-labelledby={name}
            name={name}
            onChange={handleChange}
            multiline={true}
            placeholder={placeholder}
            value={value}
            required={required}
            inputProps={{
              "data-testid": `input-${name}`,
              maxLength: maxLength ? maxLength : 10000,
            }}
            rows={multiline}
            sx={textFieldSX}
          />
        ) : (
          <TextField
            aria-labelledby={name}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            value={value}
            required={required}
            inputProps={{
              "data-testid": `input-${name}`,
              maxLength: maxLength ? maxLength : undefined,
            }}
            startAdornment={startAdornment}
            type={type ? type : "text"}
            sx={textFieldSX}
          />
        )}
        <FormHelperText>{errors}</FormHelperText>
      </FormControl>
    </div>
  );
};
