import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Insurer } from "../../types/types";

type InsurerState = {
  insurers: Array<Insurer>;
};

const initialState: InsurerState = { insurers: new Array<Insurer>() };

const insurerSlice = createSlice({
  name: "insurer",
  initialState,
  reducers: {
    setInsurers(state: InsurerState, action: PayloadAction<Insurer[]>) {
      state.insurers = action.payload;
    },
  },
});

export default insurerSlice;
