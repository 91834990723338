import {
  AccountCircleOutlined,
  ArrowForwardRounded,
  Close,
  ExitToAppRounded,
  FeedOutlined,
  LocationCityRounded,
  MailOutlineRounded,
  SearchRounded,
  SentimentSatisfiedAltRounded,
  SettingsRounded,
  SupervisorAccountRounded,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Drawer,
  List,
  ListItemButton,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { ForwardedRef, forwardRef, lazy, useState } from "react"; // TODO: Reimplement lazy loading for header to avoid stripe loading on website. - This was temporarily removed for hot reloading purposes
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as HeaderLogoWithTextSVG } from "../../images/Logo_with_text.svg";
import { ReactComponent as HeaderLogoWithoutTextSVG } from "../../images/Logos_no_text.svg";
import { truncate } from "../../services/utils-service";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import authSlice from "../../store/slices/auth";
import {
  OnboardingTask,
  PayerForUser,
  UserNotification,
  Wallet,
} from "../../types/types";
import HamburgerDrawer from "./HamburgerDrawer";
import NavigationTabsHeader from "./NavigationTabsHeader";
import { NotificationMenuActions } from "./NotificationMenuAction";
import { OnboardingTaskMenuAction } from "./OnboardingTaskMenuAction";

const HealthBudgetHeaderComponent = lazy(
  () => import("./health-budget-journey/HealthBudgetHeaderComponent"),
);

export type HeaderData = {
  loggedInEmployer: PayerForUser | null;
  onboardingTasks: OnboardingTask[];
  payersForUser: PayerForUser[];
  wallet: Wallet | null;
  notifications?: UserNotification[];
};

export type HeaderProps = {
  title: string;

  selectedTab?: string;

  showHealthBudget: boolean;

  showSkipToBody: boolean;

  showSkipToSearch: boolean;

  showSkipToProviders: boolean;

  hamburgerOverride: boolean;

  headerHeight: number;
  setHeaderHeight?: (headerHeight: number) => void;
} & HeaderData;

const NotificationsDot = ({
  showHamburger,
  show,
}: {
  showHamburger: boolean;
  show: boolean;
}) => {
  return (
    <svg
      className={
        showHamburger
          ? "absolute left-[50%] top-[50%] translate-x-[calc(-8px+10px)] translate-y-[calc(-8px-12px)]"
          : "absolute right-0 top-[50%] translate-x-[calc(-8px+10px)] translate-y-[calc(-8px-14px)]"
      }
      width="17"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!show}
    >
      {show && <title>Unseen notifications</title>}
      <circle
        cx="8.5"
        cy="8"
        r={show ? "8" : "0"}
        fill="#E35349"
        className="transition-all"
      />
      <circle
        cx="8.5"
        cy="8"
        r={show ? "1" : "0"}
        fill="white"
        className="transition-all"
      />
    </svg>
  );
};

const AccountMenu = ({
  website,
  employer,
  showHamburger,
  headerHeight,
  menuOpen,
  setMenuOpen,
  onboardingTasks,
  payersForUser,
  notifications,
}: {
  website: boolean;
  employer: PayerForUser | null;
  showHamburger: boolean;
  headerHeight: number;
  menuOpen: boolean;
  setMenuOpen: (v: boolean) => void;
  onboardingTasks: OnboardingTask[];
  payersForUser: PayerForUser[];
  notifications?: UserNotification[];
}) => {
  const { t } = useTranslation();

  const loggedInUser = useAppSelector((state) => state.userDetails.user);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(authSlice.actions.logout());
    navigate("/login");
  };
  const handleClickAccount = () => {
    setMenuOpen(true);
  };
  const handleCloseAccount = () => {
    setMenuOpen(false);
  };

  const showNotificationIndicator =
    onboardingTasks.some((task) => task.showNotificationIndicator) ||
    (notifications !== undefined && notifications.length > 0);

  const employerForUser = payersForUser.find(
    (payer) => payer.type === "employer",
  );

  return (
    <div role="menubar" className="flex flex-row justify-center items-center">
      {!showHamburger ? (
        <div className="relative">
          <Button
            variant="outlined"
            endIcon={
              <AccountCircleOutlined
                sx={{
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            onClick={() => {
              menuOpen ? handleCloseAccount() : handleClickAccount();
            }}
            aria-controls={menuOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
            data-testid="account-menu-button"
            sx={{
              paddingX: "12px",
              paddingY: "8px",
              marginY: { xs: "14px", lg: "0px" },
              borderRadius: "48px",
              fontSize: "1.1875rem",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "126.3%",
              letterSpacing: "-0.38px",
            }}
          >
            {t("navigation.account.title")}
          </Button>
          <NotificationsDot
            showHamburger={showHamburger}
            show={showNotificationIndicator}
          />
        </div>
      ) : (
        <button
          tabIndex={1}
          onClick={() => {
            menuOpen ? handleCloseAccount() : handleClickAccount();
          }}
          aria-label={t("navigation.account.title")}
          aria-expanded={menuOpen ? "true" : undefined}
          aria-haspopup="true"
          data-testid="account-menu-button"
          className="h-[100%] w-[100%] px-[24px] relative"
        >
          {menuOpen ? (
            <Close sx={{ color: "#2C2C2C", width: "32px", height: "32px" }} />
          ) : (
            <>
              <MenuIcon
                sx={{ color: "#2C2C2C", width: "32px", height: "32px" }}
              />
              <NotificationsDot
                showHamburger={showHamburger}
                show={showNotificationIndicator}
              />
            </>
          )}
        </button>
      )}
      <Drawer
        id="basic-menu"
        open={menuOpen}
        onClose={handleCloseAccount}
        anchor="right"
        sx={{
          "> .MuiPaper-root.MuiPaper-elevation": {
            width: showHamburger ? "100%" : "400px",
            height: `calc(100vh - ${headerHeight}px)`,
            top: `${headerHeight}px`,
            justifyContent: "space-between",
            paddingBottom: "24px",
            paddingTop: "24px",
          },
          ".MuiBackdrop-root": {
            backgroundColor: "#2C2C2C1a",
          },
        }}
      >
        {notifications && (
          <div>
            {notifications.map((notification) => (
              <NotificationMenuActions
                key={notification.type}
                notificationBubble={true}
                type={notification.type}
                closeMenu={handleCloseAccount}
                title={notification.title}
                text={notification.text}
              />
            ))}
          </div>
        )}
        <div className="flex-1">
          {onboardingTasks.map((onboardingTask) => (
            <OnboardingTaskMenuAction
              key={onboardingTask.onboardingTask}
              notificationBubble={onboardingTask.showNotificationIndicator}
              task={onboardingTask.onboardingTask}
              closeMenu={handleCloseAccount}
              employerId={employerForUser?.id}
              remindMe={onboardingTask.remindMe}
            />
          ))}
        </div>
        <List>
          <ListItemButton
            onClick={() => navigate("/profile/personal")}
            sx={{
              borderBottom: "1px solid #CEE0FD",
              "&:hover": {
                borderColor: "#CEE0FD",
                background: "#E7F1FB",
              },
            }}
          >
            <Typography variant="titleXS">
              {t("navigation.account.account-settings")}
            </Typography>
            <SettingsRounded
              sx={{
                marginLeft: "auto",
                width: "20px",
                height: "20px",
                color: "#105A9A",
              }}
            />
          </ListItemButton>
          {payersForUser &&
            payersForUser.length > 0 &&
            payersForUser.map(
              (payer, index) =>
                payer.isAdmin && (
                  <ListItemButton
                    key={payer.name + index}
                    onClick={() =>
                      navigate(`/employerPortal/${payer.id}/dashboard`)
                    }
                    sx={{
                      borderBottom: "1px solid #CEE0FD",
                      "&:hover": {
                        borderColor: "#CEE0FD",
                        background: "#E7F1FB",
                      },
                    }}
                  >
                    <Typography variant="titleXS">
                      {payer?.name
                        ? truncate(payer?.name, 38)
                        : t("navigation.employer-settings")}
                    </Typography>
                    <LocationCityRounded
                      sx={{
                        marginLeft: "auto",
                        width: "20px",
                        height: "20px",
                        color: "#105A9A",
                      }}
                    />
                  </ListItemButton>
                ),
            )}
          {loggedInUser?.provider_id && !website && (
            <ListItemButton
              onClick={() => navigate("/healthPartner/dashboard")}
              sx={{
                borderBottom: "1px solid #CEE0FD",
                "&:hover": {
                  borderColor: "#CEE0FD",
                  background: "#E7F1FB",
                },
              }}
            >
              <Typography variant="titleXS">
                {t("navigation.provider-settings")}
              </Typography>
              <LocationCityRounded
                sx={{
                  marginLeft: "auto",
                  width: "20px",
                  height: "20px",
                  color: "#105A9A",
                }}
              />
            </ListItemButton>
          )}
          {loggedInUser?.is_staff && !website && (
            <ListItemButton
              onClick={() => navigate("/adminPortal/home")}
              sx={{
                borderBottom: "1px solid #CEE0FD",
                "&:hover": {
                  borderColor: "#CEE0FD",
                  background: "#E7F1FB",
                },
              }}
            >
              <Typography variant="titleXS">
                {t("navigation.admin-portal")}
              </Typography>
              <SupervisorAccountRounded
                sx={{
                  marginLeft: "auto",
                  width: "20px",
                  height: "20px",
                  color: "#105A9A",
                }}
              />
            </ListItemButton>
          )}
          <ListItemButton
            onClick={() => navigate("/contact")}
            sx={{
              borderBottom: "1px solid #CEE0FD",
              "&:hover": {
                borderColor: "#CEE0FD",
                background: "#E7F1FB",
              },
            }}
          >
            <Typography variant="titleXS">
              {t("navigation.account.contact")}
            </Typography>
            <MailOutlineRounded
              sx={{
                marginLeft: "auto",
                width: "20px",
                height: "20px",
                color: "#105A9A",
              }}
            />
          </ListItemButton>
          <ListItemButton
            role="menuitem"
            onClick={handleLogout}
            sx={{
              borderBottom: "1px solid #CEE0FD",
              "&:hover": {
                borderColor: "#CEE0FD",
                background: "#E7F1FB",
              },
            }}
          >
            <Typography variant="titleXS">
              {t("navigation.account.logout")}
            </Typography>
            <ExitToAppRounded
              sx={{
                marginLeft: "auto",
                width: "20px",
                height: "20px",
                color: "#105A9A",
              }}
            />
          </ListItemButton>
        </List>
      </Drawer>
    </div>
  );
};

const Header = forwardRef(function InnerHeader(
  props: HeaderProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const loggedInUser = useAppSelector((state) => state.userDetails.user);
  const [value, setValue] = useState(props.selectedTab);
  const [menuOpen, setMenuOpen] = useState(false);
  const [healthBudgetJourneyModalIsOpen, setHealthBudgetJourneyModalIsOpen] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState("0");
  const [connectEmployerModalOpen, setConnectEmployerModalOpen] =
    useState(false);
  const [connectGymModalOpen, setConnectGymModalOpen] = useState(false);
  const website: boolean =
    location !== undefined &&
    location.pathname !== undefined &&
    ((location.pathname.includes("home") &&
      !location.pathname.includes("admin")) ||
      location.pathname.includes("help") ||
      location.pathname.includes("industry") ||
      location.pathname === "/contact" ||
      location.pathname === "/FAQs" ||
      location.pathname === "/contact-employers" ||
      location.pathname.includes("pricing") ||
      (location.pathname.includes("findCare") && loggedInUser === null) ||
      location.pathname.includes("about") ||
      location.pathname === "/");
  const theme = useTheme();
  const isBiggerThanLgBreakpoint = useMediaQuery(theme.breakpoints.up("lg"));
  const isBiggerThanSmBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));

  const showHamburger = !isBiggerThanLgBreakpoint || props.hamburgerOverride;

  return (
    <>
      <AppBar
        ref={ref}
        sx={{
          background: "#fff",
          paddingY: {
            lg: "0px",
          },
        }}
      >
        <Toolbar
          sx={{
            width: "100%",
            maxWidth: {
              xl: "1380px",
              xxxl: "1500px",
            },
            paddingX: {
              xs: "0",
              sm: "32px",
              md: "40px",
            },
            marginX: "auto",
            display: "flex",
            flexDirection: "column",
            "&.MuiToolbar-root": {
              minHeight: "56px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div className="flex-1 lg:flex-initial flex flex-row items-center gap-3 sm:gap-6">
              <button
                tabIndex={1}
                aria-label="Home"
                aria-pressed="false"
                className="cursor-pointer pl-[16px] py-[16px] md:pl-0"
                onClick={() => navigate(!website ? "/you" : "/")}
                onKeyDown={(event) => {
                  if (event.key === "Enter") navigate(!website ? "/you" : "/");
                }}
              >
                {isBiggerThanSmBreakpoint ? (
                  <HeaderLogoWithTextSVG className="w-[140px] h-[26.7px] xl:w-[164px]" />
                ) : loggedInUser ? (
                  <HeaderLogoWithoutTextSVG className="w-[48px] h-[27.4px]" />
                ) : (
                  <HeaderLogoWithTextSVG className="w-[140px] h-[26.7px] xl:w-[164px]" />
                )}
              </button>
              {props.loggedInEmployer?.cobrandingLogo && (
                <>
                  <div className="w-[1px] bg-blue-2 h-[40px]"></div>
                  <img
                    className="w-full max-w-[95px]"
                    src={props.loggedInEmployer?.cobrandingLogo ?? ""}
                    alt={`${props.loggedInEmployer?.cobrandingName} Logo`}
                  />
                </>
              )}
            </div>
            {!showHamburger && (
              <div className="flex-1 flex flex-row flex-wrap items-center justify-between pl-4 xl:pl-6">
                <NavigationTabsHeader
                  employer={props.loggedInEmployer}
                  selectedTab={props.selectedTab}
                  website={website}
                />
                <div className="focus:flex-1">
                  {props.showSkipToBody && (
                    <a href="#firstContent" tabIndex={1} className="skip-link">
                      Skip to body
                    </a>
                  )}
                  {props.showSkipToProviders && (
                    <a href="#providersList" tabIndex={1} className="skip-link">
                      Skip to providers list
                    </a>
                  )}
                  {props.showSkipToSearch && (
                    <a href="#searchBar" tabIndex={1} className="skip-link">
                      Skip to search
                    </a>
                  )}
                </div>
                <div className="flex flex-row gap-2">
                  {loggedInUser && !website && (
                    <HealthBudgetHeaderComponent
                      healthBudgetJourneyModalIsOpen={
                        healthBudgetJourneyModalIsOpen
                      }
                      setHealthBudgetJourneyModalIsOpen={
                        setHealthBudgetJourneyModalIsOpen
                      }
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      wallet={props.wallet ?? undefined}
                      connectEmployerModalOpen={connectEmployerModalOpen}
                      setConnectEmployerModalOpen={setConnectEmployerModalOpen}
                      loggedInEmployer={props.loggedInEmployer ?? undefined}
                      usersPayers={props.payersForUser}
                      connectGymModalOpen={connectGymModalOpen}
                      setConnectGymModalOpen={setConnectGymModalOpen}
                    />
                  )}
                  {loggedInUser && !website ? (
                    <AccountMenu
                      website={website}
                      employer={props.loggedInEmployer}
                      onboardingTasks={props.onboardingTasks}
                      showHamburger={showHamburger}
                      headerHeight={props.headerHeight}
                      setMenuOpen={setMenuOpen}
                      menuOpen={menuOpen}
                      payersForUser={props.payersForUser}
                      notifications={props.notifications}
                    />
                  ) : (
                    <div className="flex flex-row items-center gap-2 xl:gap-4">
                      <Button
                        tabIndex={1}
                        variant="outlined"
                        onClick={() => navigate("/login")}
                        sx={{
                          textWrap: "nowrap",
                          marginY: "18px",
                        }}
                      >
                        {t("navigation.login")}
                      </Button>
                      <Button
                        tabIndex={1}
                        variant="contained"
                        onClick={() => navigate("/login")}
                        sx={{
                          textWrap: "nowrap",
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                          marginY: "18px",
                        }}
                      >
                        {t("navigation.sign-up")}
                        <ArrowForwardRounded
                          sx={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {showHamburger &&
              (loggedInUser && !website ? (
                <div className="flex flex-row items-center sm:gap-2">
                  <HealthBudgetHeaderComponent
                    healthBudgetJourneyModalIsOpen={
                      healthBudgetJourneyModalIsOpen
                    }
                    setHealthBudgetJourneyModalIsOpen={
                      setHealthBudgetJourneyModalIsOpen
                    }
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    wallet={props.wallet ?? undefined}
                    connectEmployerModalOpen={connectEmployerModalOpen}
                    setConnectEmployerModalOpen={setConnectEmployerModalOpen}
                    loggedInEmployer={props.loggedInEmployer ?? undefined}
                    usersPayers={props.payersForUser}
                    connectGymModalOpen={connectGymModalOpen}
                    setConnectGymModalOpen={setConnectGymModalOpen}
                  />
                  <AccountMenu
                    website={website}
                    employer={props.loggedInEmployer}
                    showHamburger={showHamburger}
                    headerHeight={props.headerHeight}
                    setMenuOpen={setMenuOpen}
                    menuOpen={menuOpen}
                    onboardingTasks={props.onboardingTasks}
                    notifications={props.notifications}
                    payersForUser={props.payersForUser}
                  />
                </div>
              ) : (
                <HamburgerDrawer />
              ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Paper
        className={`bg-white border-grey-1 border px-4 py-4 z-30 ${showHamburger && !website ? "block" : "hidden"}`}
        sx={{
          margin: "0",
          padding: "0",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: "0",
        }}
        elevation={0}
      >
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
        >
          <BottomNavigationAction
            label={t("navigation.you")}
            icon={
              <SentimentSatisfiedAltRounded
                sx={{
                  color: value === "0" ? "#D83E1D" : "#373737",
                }}
              />
            }
            value={"0"}
            component={Link}
            to={"/you"}
          />
          <BottomNavigationAction
            label={t("navigation.browse-care")}
            icon={
              <SearchRounded
                sx={{
                  color: value === "1" ? "#D83E1D" : "#373737",
                }}
              />
            }
            value={"1"}
            component={Link}
            to={"/findCare"}
          />
          <BottomNavigationAction
            label={t("navigation.content")}
            icon={
              <FeedOutlined
                sx={{
                  color: value === "2" ? "#D83E1D" : "#373737",
                }}
              />
            }
            value={"2"}
            component={Link}
            to={"/content"}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
});

export default Header;
