import { FormControlLabel, FormControlLabelProps } from "@mui/material";

export const HKFormControlLabel = (props: FormControlLabelProps) => {
  return (
    <FormControlLabel
      {...props}
      sx={{
        "& .MuiFormControlLabel-label": {
          fontSize: "19px",
          fontWeight: "600",
          color: "#212529",
        },
        ...props.sx,
      }}
    />
  );
};
