import { Radio, RadioGroup } from "@mui/material";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { HKFormControlLabel } from "../../../../components/design-system/form/HKFormControlLabel";
import RadioAccordion from "./RadioAccordion";
import { HKInput } from "../../../../components/design-system/form/inputs/HKInput";
import { HKSelect } from "../../../../components/design-system/form/inputs/HKSelect";

export const schemaForHealthBudgetEditor = (values: any) => {
  let schema: Yup.ObjectSchema<any>;
  if (values.topUpFrequency === "one_off" && values.strategyValue === "none") {
    schema = Yup.object({});
  } else if (values.topUpFrequency === "one_off") {
    schema = Yup.object({
      topUpAmount: Yup.number()
        .required()
        .min(1, "Please enter a top up amount of at least £1")
        .typeError("Please enter a number"),
    });
  } else if (
    values.topUpFrequency === "recurrent" &&
    values.monthOfTheTopUp === 0
  ) {
    schema = Yup.object({
      topUpAmount: Yup.number()
        .required()
        .min(1, "Please enter a top up amount of at least £1")
        .typeError("Please enter a number"),
      dayOfTheTopUp: Yup.number()
        .required()
        .min(1, "Please select a date")
        .max(28, "Please select a date"),
    });
  } else {
    schema = Yup.object({
      topUpAmount: Yup.number()
        .required()
        .typeError("Please enter a number")
        .min(1, "Please enter a top up amount of at least £1"),
      dayOfTheTopUp: Yup.number()
        .required()
        .min(1, "Please select a date")
        .max(28, "Please select a date"),
      monthOfTheTopUp: Yup.number()
        .required()
        .min(1, "Please select a date")
        .max(12, "Please select a date"),
    });
  }
  return schema;
};

const TransComponent = ({ i18nKey }: { i18nKey: string }) => {
  return (
    <Trans
      i18nKey={i18nKey}
      components={{
        b: <b />,
        br: <br />,
      }}
    />
  );
};

const formatOrdinal = (n: number) => {
  switch (n) {
    case 1:
      return "1st";
    case 2:
      return "2nd";
    case 3:
      return "3rd";
    default:
      return `${n}th`;
  }
};

export type HealthBudgetEditorProps = {
  formikEmployerProfile: any;
};
export const HealthBudgetEditor = ({
  formikEmployerProfile,
}: HealthBudgetEditorProps) => {
  const { t } = useTranslation();

  type TopUpOptions =
    | "yearly-allowance"
    | "monthly-allowance"
    | "one_off"
    | "no-budget";

  let selectedTopUpOption: TopUpOptions;
  if (
    formikEmployerProfile.values.topUpFrequency === "recurrent" &&
    formikEmployerProfile.values.monthOfTheTopUp === 0
  ) {
    selectedTopUpOption = "monthly-allowance";
  } else if (formikEmployerProfile.values.topUpFrequency === "recurrent") {
    selectedTopUpOption = "yearly-allowance";
  } else if (
    formikEmployerProfile.values.topUpFrequency === "one_off" &&
    formikEmployerProfile.values.strategyValue === "standard"
  ) {
    selectedTopUpOption = "one_off";
  } else if (
    formikEmployerProfile.values.topUpFrequency === "one_off" &&
    formikEmployerProfile.values.strategyValue === "none"
  ) {
    selectedTopUpOption = "no-budget";
  } else {
    throw new Error("Unexpected value for frequency in HealthBudgetEditor");
  }

  return (
    <div className="max-w-[800px]">
      <RadioAccordion
        value={selectedTopUpOption}
        onChange={(_, value) => {
          switch (value) {
            case "yearly-allowance":
              formikEmployerProfile.setValues({
                ...formikEmployerProfile.values,
                monthOfTheTopUp: 1,
                topUpFrequency: "recurrent",
                renewFrequency: "yearly",
              });
              break;
            case "monthly-allowance":
              formikEmployerProfile.setValues({
                ...formikEmployerProfile.values,
                monthOfTheTopUp: 0,
                topUpFrequency: "recurrent",
                renewFrequency: "monthly",
              });
              break;
            case "one_off":
              formikEmployerProfile.setValues({
                ...formikEmployerProfile.values,
                topUpFrequency: "one_off",
                strategyValue: "standard",
              });
              break;
            case "no-budget":
              formikEmployerProfile.setValues({
                ...formikEmployerProfile.values,
                topUpFrequency: "one_off",
                strategyValue: "none",
              });
              break;
            default:
              throw new Error(
                "Unexpected value for frequency in HealthBudgetEditor",
              );
          }
        }}
        options={[
          {
            value: "yearly-allowance",
            label: t(
              "employer-portal.onboarding.health-budget.yearly-allowance",
            ),
            content: (
              <>
                <div className="flex flex-col gap-4 pt-4 pb-4 px-3 sm:px-4">
                  <div className="flex flex-col md:flex-row gap-2">
                    <div className="flex-1">
                      <HKInput
                        name="topUpAmount"
                        label={t(
                          "employer-portal.onboarding.health-budget.amount",
                        )}
                        required
                        value={formikEmployerProfile.values.topUpAmount}
                        handleChange={formikEmployerProfile.handleChange}
                        errors={
                          formikEmployerProfile.touched.topUpAmount &&
                          formikEmployerProfile.errors.topUpAmount
                        }
                        startAdornment={
                          <span style={{ paddingRight: "4px" }}>{"£"}</span>
                        }
                        textFieldSX={{
                          maxWidth: "400px",
                        }}
                      />
                    </div>
                    <div className="flex-1">
                      <HKSelect
                        handleChange={formikEmployerProfile.handleChange}
                        value={formikEmployerProfile.values.monthOfTheTopUp}
                        options={Array.from(
                          { length: 12 },
                          (x, i) => i + 1,
                        ).map((i) => ({
                          value: `${i}`,
                          label: moment.months()[i - 1],
                        }))}
                        name="monthOfTheTopUp"
                        label={t(
                          "employer-portal.onboarding.health-budget.payment-month",
                        )}
                        errors={
                          formikEmployerProfile.touched.monthOfTheTopUp &&
                          formikEmployerProfile.errors.monthOfTheTopUp
                        }
                        required
                        selectSX={{
                          maxWidth: "400px",
                        }}
                      />
                    </div>
                    <div className="flex-1">
                      <HKSelect
                        handleChange={formikEmployerProfile.handleChange}
                        value={formikEmployerProfile.values.dayOfTheTopUp}
                        options={Array.from(
                          { length: 28 },
                          (x, i) => i + 1,
                        ).map((i) => ({
                          value: i,
                          label: `${formatOrdinal(i)}`,
                        }))}
                        name="dayOfTheTopUp"
                        label={t(
                          "employer-portal.onboarding.health-budget.payment-date",
                        )}
                        errors={
                          formikEmployerProfile.touched.dayOfTheTopUp &&
                          formikEmployerProfile.errors.dayOfTheTopUp
                        }
                        required
                        selectSX={{
                          maxWidth: "400px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ),
          },
          {
            value: "monthly-allowance",
            label: t(
              "employer-portal.onboarding.health-budget.monthly-allowance",
            ),
            content: (
              <div className="flex flex-col gap-4 pt-4 pb-4 px-3 sm:px-4">
                <div className="flex flex-col md:flex-row gap-2">
                  <div className="flex-1">
                    <HKInput
                      name="topUpAmount"
                      label="Recurrent top-up amount"
                      required
                      value={formikEmployerProfile.values.topUpAmount}
                      handleChange={formikEmployerProfile.handleChange}
                      errors={
                        formikEmployerProfile.touched.topUpAmount &&
                        formikEmployerProfile.errors.topUpAmount
                      }
                      startAdornment={
                        <span style={{ paddingRight: "4px" }}>{"£"}</span>
                      }
                      textFieldSX={{
                        maxWidth: "400px",
                      }}
                    />
                  </div>
                  <div className="flex-1">
                    <HKSelect
                      handleChange={formikEmployerProfile.handleChange}
                      value={formikEmployerProfile.values.dayOfTheTopUp}
                      options={Array.from({ length: 28 }, (x, i) => i + 1).map(
                        (i) => ({
                          value: i,
                          label: `${formatOrdinal(i)}`,
                        }),
                      )}
                      name="dayOfTheTopUp"
                      label={t(
                        "employer-portal.onboarding.health-budget.payment-date",
                      )}
                      errors={
                        formikEmployerProfile.touched.dayOfTheTopUp &&
                        formikEmployerProfile.errors.dayOfTheTopUp
                      }
                      required
                      selectSX={{
                        maxWidth: "400px",
                      }}
                    />
                  </div>
                </div>
              </div>
            ),
          },
          {
            value: "one_off",
            label: t("employer-portal.onboarding.health-budget.one-off-amount"),
            content: (
              <div
                data-testid="one-off-amount-input"
                className="flex flex-col gap-4 pt-4 pb-4 px-3 sm:px-4"
              >
                <HKInput
                  name="topUpAmount"
                  label={t("employer-portal.onboarding.health-budget.amount")}
                  required
                  value={formikEmployerProfile.values.topUpAmount}
                  handleChange={formikEmployerProfile.handleChange}
                  errors={
                    formikEmployerProfile.touched.topUpAmount &&
                    formikEmployerProfile.errors.topUpAmount
                  }
                  startAdornment={
                    <span style={{ paddingRight: "4px" }}>{"£"}</span>
                  }
                  textFieldSX={{
                    maxWidth: "400px",
                  }}
                />
              </div>
            ),
          },
          {
            value: "no-budget",
            label: t("employer-portal.onboarding.health-budget.no-budget"),
            content: (
              <div className="flex flex-col gap-2 pt-4 pb-4 sm:px-4  max-w-[540px]">
                <p>
                  <TransComponent i18nKey="employer-portal.onboarding.health-budget.no-budget-description" />
                </p>
                <p>
                  <TransComponent i18nKey="employer-portal.onboarding.health-budget.no-budget-description-2" />
                </p>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
