import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { UserNotificationEnum } from "../../types/types";
import { MenuAction } from "./MenuAction";

const openNotification = (
  type: UserNotificationEnum,
  navigate: ReturnType<typeof useNavigate>,
  closeMenu: () => void,
) => {
  switch (type) {
    case UserNotificationEnum.SUBSCRIPTION_RENEWAL:
      navigate("/profile/subscriptions/subscriptions");
      break;
  }

  closeMenu();
};

export const NotificationMenuActions = ({
  type,
  notificationBubble,
  closeMenu,
  title,
  text,
}: {
  notificationBubble: boolean;
  type: UserNotificationEnum;
  title: string;
  text: string;
  closeMenu: () => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <MenuAction
      notificationBubble={notificationBubble}
      goToAction={() => openNotification(type, navigate, closeMenu)}
      title={title}
      text={text}
    />
  );
};
