import { Typography } from "@mui/material";

export type NoticeProps = {
  children: React.ReactNode;
};

/**
 * @deprecated Use Callout
 */
export const Notice = ({ children }: NoticeProps) => (
  <div className="bg-blue-1 rounded-[8px] py-[8px] px-[16px]">
    <Typography color="#005BA1" variant="body">
      {children}
    </Typography>
  </div>
);
