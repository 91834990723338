import { OutlinedInput, OutlinedInputProps } from "@mui/material";

export const TextField = (props: OutlinedInputProps) => {
  return (
    <OutlinedInput
      {...props}
      sx={{ marginTop: "0px", ...props.sx }}
      size="medium"
    />
  );
};
