export const PageMargins = ({
  fullWidth,
  children,
}: {
  fullWidth?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={`flex-1 ${fullWidth ? "" : "px-4 sm:px-8 md:px-10 m-auto  xl:max-w-[1380px] 3xl:max-w-[1500px]"}  w-full`}
    >
      {children}
    </div>
  );
};
