import { Box, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { PageMargins } from "../page-template/PageMargins";

export interface FooterProps {
  selectedTab: string;
}

const Footer = (props: FooterProps) => {
  const { t } = useTranslation();

  return (
    <footer>
      <PageMargins>
        <div className="flex flex-col md:flex-row md:flex-wrap pt-14 md:pt-24 pb-2 md:pb-8 bg-background mb-[110px] items-center">
          <Typography
            variant="bodyS"
            sx={{
              flex: 1,
            }}
          >
            Copyright © 2024 HealthKey
          </Typography>
          <div className="grid grid-cols-2 md:grid-cols-4 md:gap-6 w-full md:w-auto">
            <Link
              variant="body"
              className="cky-banner-element"
              rel="noreferrer"
              target="_blank"
              sx={{
                textAlign: "center",
                paddingY: "0.625rem",
              }}
            >
              {t("navigation.cookie-settings")}
            </Link>
            <Link
              variant="body"
              href={process.env.REACT_APP_PRIVACY_POLICY_URL}
              rel="noreferrer"
              target="_blank"
              sx={{
                textAlign: "center",
                paddingY: "0.625rem",
              }}
            >
              {t("navigation.privacy-policy")}
            </Link>
            <Link
              variant="body"
              href={process.env.REACT_APP_TOS_USERS}
              rel="noreferrer"
              target="_blank"
              sx={{
                textAlign: "center",
                paddingY: "0.625rem",
              }}
            >
              {t("navigation.terms")}
            </Link>
            <Link
              variant="body"
              href="/siteMap"
              rel="noreferrer"
              sx={{
                textAlign: "center",
                paddingY: "0.625rem",
              }}
            >
              {t("navigation.site-map")}
            </Link>
          </div>
        </div>
      </PageMargins>
    </footer>
  );
};

export default Footer;
