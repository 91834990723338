import { Typography } from "@mui/material";

export type TitleLProps = {
  children: React.ReactNode;
};

/**
 * @deprecated
 */
export const TitleL = ({ children }: TitleLProps) => (
  <Typography variant="titleL">{children}</Typography>
);
