import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useUpdateOnboardingTasksMutation } from "../../store/slices/api/users";
import onboardingReminders from "../../store/slices/onboardingReminders";
import { OnboardingTaskEnum } from "../../types/types";
import { MenuAction } from "./MenuAction";

export const getTitleAndDescriptionForOnboardingTask = (
  task: OnboardingTaskEnum,
  t: ReturnType<typeof useTranslation>["t"],
): {
  title: string;
  description: string;
} => {
  return {
    title: t(`menu-actions.${task}.title`),
    description: t(`menu-actions.${task}.description`),
  };
};

const openTask = (
  task: OnboardingTaskEnum,
  navigate: ReturnType<typeof useNavigate>,
  closeMenu: () => void,
  dispatch: ReturnType<typeof useAppDispatch>,
  employerId?: string,
) => {
  switch (task) {
    case OnboardingTaskEnum.CREATE_EMPLOYER_NEXT_STEPS:
      dispatch(onboardingReminders.actions.setOnboardingReminderSeen(false));
      navigate("/you");
      break;
    case OnboardingTaskEnum.COMPLETE_PROFILE:
      navigate("/profile/personal");
      break;
    case OnboardingTaskEnum.CONNECT_EMPLOYER:
      navigate("/profile/organisations/employer", {});
      break;
    case OnboardingTaskEnum.CONNECT_GYM:
      navigate("/profile/organisations/gym", {});
      break;
    case OnboardingTaskEnum.CREATE_EMPLOYER:
      navigate("/createEmployer");
      break;
    case OnboardingTaskEnum.SETUP_PERSONALISATION:
      navigate("/you?personalise=true");
      break;
    case OnboardingTaskEnum.SUBSCRIBE_NEWSLETTER:
      navigate("/profile/email-notifications");
      break;
  }

  closeMenu();
};

const clearNotificationIcon = (task: OnboardingTaskEnum) => {
  const nonDismissibleDismisableNotificationIcons = [
    OnboardingTaskEnum.CREATE_EMPLOYER,
  ];
  return !nonDismissibleDismisableNotificationIcons.includes(task);
};

export const OnboardingTaskMenuAction = ({
  task,
  employerId,
  notificationBubble,
  remindMe,
  closeMenu,
}: {
  notificationBubble: boolean;
  remindMe: boolean;
  task: OnboardingTaskEnum;
  employerId?: string;

  closeMenu: () => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector((state) => state.userDetails.user);

  const [updateOnboardingTasks] = useUpdateOnboardingTasksMutation();

  const { title, description } = getTitleAndDescriptionForOnboardingTask(
    task,
    t,
  );

  return (
    <MenuAction
      notificationBubble={notificationBubble}
      goToAction={() =>
        openTask(task, navigate, closeMenu, dispatch, employerId)
      }
      title={title}
      text={description}
      onMouseOver={async () => {
        if (notificationBubble && clearNotificationIcon(task))
          await updateOnboardingTasks({
            onboardingTask: {
              onboardingTask: task,
              showNotificationIndicator: false,
              remindMe: remindMe,
            },
            userId: loggedInUser?.id ?? "",
          });
      }}
    />
  );
};
