import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { NavigationProps } from "../../../types/props";

const NavigationTabsEmployer = (
  props: NavigationProps & { payerId: string | undefined },
) => {
  const [value, setValue] = useState(props.selectedTab);

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  const { t } = useTranslation();

  const tabStyling = {
    fontSize: "1rem",
    fontFamily: [
      "Karla",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "700",
    lineHeight: "150%",
    letterSpacing: "-0.0225rem",
    padding: "0px 50px 0px 50px",
    textTransform: "unset",
    "&.Mui-selected": {
      color: "#105A9A",
    },
  };
  return (
    <Box
      sx={{
        width: "100%",
        paddingBottom: "16px",
      }}
    >
      <Box className="hidden sm:block">
        <TabContext value={value}>
          <TabList
            onChange={handleChange}
            textColor="secondary"
            classes={{
              flexContainer: "flexContainer",
              indicator: "indicator",
            }}
            TabIndicatorProps={{ children: <span /> }}
            sx={{
              "& .indicator": {
                display: "flex",
                justifyContent: "center",
                backgroundColor: "transparent",
                "& > span": {
                  maxWidth: 50,
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#105A9A",
                },
              },
            }}
          >
            <Tab
              sx={tabStyling}
              label={t("employer-portal.navigation.insights")}
              value={"0"}
              component={Link}
              to={`/employerPortal/${props.payerId}/dashboard`}
            />
            <Tab
              sx={tabStyling}
              label={t("employer-portal.navigation.manage-users")}
              value={"1"}
              component={Link}
              to={`/employerPortal/${props.payerId}/manage-users`}
            />
            <Tab
              sx={tabStyling}
              label={t("employer-portal.navigation.benefits")}
              value={"2"}
              component={Link}
              to={`/employerPortal/${props.payerId}/providers`}
            />
            <Tab
              sx={tabStyling}
              label={t("employer-portal.navigation.company-settings")}
              value={"3"}
              component={Link}
              to={`/employerPortal/${props.payerId}/settings/budget`}
            />
          </TabList>
        </TabContext>
      </Box>
    </Box>
  );
};

export default NavigationTabsEmployer;
